@use "../../styles/colors.scss";

.ThemeToggle {
  &-Ball {
    background-color: colors.$dark-color;
    border-radius: 50%;
    height: 15px;
    left: 3.5px;
    position: absolute;
    top: 3.5px;
    transition: transform 0.2s linear;
    width: 15px;

    &--isChecked {
      transform: translateX(18px);
    }
  }

  &-Checkbox {
    opacity: 0;
    position: absolute;
  }

  &-Label {
    align-items: center;
    background-color: colors.$black-background-color;
    border-radius: 50px;
    display: flex;
    height: 22px;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
    width: 40px;
  }

  &-Moon {
    color: pink;
  }

  &-Sun {
    color: yellow;
  }

  &-Wrapper {
    align-items: center;
    display: flex;
  }
}
