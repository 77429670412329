/* Dark theme specific variables */
$dark-background-color: #292c35;
$dark-color: #f2f2f5;
$dark-link-color: #03dac6;

/* Light theme specific variables */
$light-background-color: #f2f2f5;
$light-color: #292c35;
$light-link-color: #6200ee;

/* Footer and ThemeToggle label */
$black-background-color: #111;

/* Current unused colors that match */
$error: #B00020;
$error-light: #CF6679;