@use "../../styles/colors.scss";

.NotFound {
  &--dark {
    color: colors.$dark-color;
  }

  &--light {
    color: colors.$light-color;
  }

  &-Center {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: center;
    padding: 10%;
    width: 100%;

    & h1 {
      font-size: 2rem;
    }

    & p {
      margin-top: 1rem;
    }

    &--dark {
      background-color: colors.$dark-background-color;
    }

    &--light {
      background-color: colors.$light-background-color;
    }
  }
}
