@use "../../styles/colors.scss";

.Home {
  &-Center {
    align-items: center;
    display: flex;
    flex-grow: 2;
    justify-content: center;
    padding: 2rem;
    width: 100%;

    &--dark {
      background-color: colors.$dark-background-color;
    }

    &--light {
      background-color: colors.$light-background-color;
    }
  }
}
