@use "../../styles/colors.scss";

.NavBar {
  &-Link {
    transition: 0.1s;

    &--dark {
      &--isActive {
        color: colors.$dark-link-color;
      }

      &:hover {
        color: colors.$dark-link-color;
      }
    }

    &--light {
      &--isActive {
        color: colors.$light-link-color;
      }

      &:hover {
        color: colors.$light-link-color;
      }
    }
  }

  &-Nav {
    align-items: center;
    display: flex;
    height: 2.4rem;
    // height: 10%;
    justify-content: space-between;
    padding: 1.5rem;
    padding-inline-end: 1rem;
    width: 100%;
    z-index: 2;

    &--dark {
      color: colors.$dark-color;
    }

    &--light {
      color: colors.$light-color;
    }
  }

  &-Slash {
    opacity: 0.5;
    padding-inline: 1rem;
  }

  &-UnorderedList {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-block: 1rem;
  }
}
