@use "../../styles/colors.scss";

.App {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;

  &-Wrapper {
    height: 100%;
    width: 100%;

    &--dark {
      background-color: colors.$dark-background-color;
      color: colors.$dark-color;
    }

    &--light {
      background-color: colors.$light-background-color;
      color: colors.$light-color;
      font-weight: 500;
    }
  }
}

html,
body,
main,
#root {
  background-color: colors.$black-background-color;
  height: 100%;
  width: 100%;
}
