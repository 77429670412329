@use "../../styles/colors.scss";

.HoustonBreedlove {
  z-index: 2;
  &--dark {
    color: colors.$dark-color;
  }

  &--light {
    color: colors.$light-color;
  }
  
  font-size: 2.5rem;
}
